<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pt-5"> </base-header>
    <div class="container-fluid mt--6">
      <time-line v-if="showTimeLine"></time-line>
      <div class="col">
        <div class="row icon-examples dashboard"
          style="background-color: #e0ebff"
        >
          <div class="col-12 org" style="margin-left: 25px;">
            <a class="heading-color" href="#">Workflow Documentation</a>
          </div>
          <div
            class="col-lg-3 col-md-6"
            v-for="(icon, index) in documents"
            :key="icon.name + index"
          >
            <a
              type="button"
              v-b-tooltip.hover.top
              :title="icon.hovertext"
              :class="icon.classes"
              data-clipboard-text="air-baloon"
              :href="icon.path"
            >
              <div>
                <!-- <router-link class="navbar-brand" :to="icon.path"> -->
                <img :src="icon.icon" style="width: 35px; height: 35px" />
                <span>{{ icon.name.toUpperCase() }}</span>
                <!-- </router-link> -->
              </div>
            </a>
          </div>
          <!-- <div class="col-12 org">
              <a href="#">Document Management</a>
            </div> -->
        </div>
        <div class="row icon-examples dashboard">
          <div class="col-12 org" style="margin-left: -28px;">
            <a class="heading-color" href="#">Organizer</a>
          </div>
          <div
            class="col-lg-3 col-md-6"
            v-for="(icon, index) in organizer"
            :key="icon.name + index"
          >
            <a
              type="button"
              v-b-tooltip.hover.top
              :title="icon.hovertext"
              :class="icon.classes"
              data-clipboard-text="air-baloon"
              :href="icon.path"
            >
              <div>
                <!-- <router-link class="navbar-brand" :to="icon.path"> -->
                <i :class="icon.icon"></i>
                <span>{{ icon.name.toUpperCase() }}</span>
                <!-- </router-link> -->
              </div>
            </a>
          </div>
        </div>
        <div
        class="row icon-examples dashboard"
        style="background-color: #105888"
      >
        <div class="col-12 org" style="margin-left: 10px;">
          <a class="heading-color" href="#">File Storage Shelves</a>
        </div>
        <div
          class="col-lg-3 col-md-6"
          v-for="(icon, index) in media"
          :key="icon.name + index"
        >
          <a
            type="button"
            v-b-tooltip.hover.top
            :title="icon.hovertext"
            :class="icon.classes"
            data-clipboard-text="air-baloon"
            :href="icon.path"
          >
            <div>
              <!-- <router-link class="navbar-brand" :to="icon.path"> -->
              <img :src="icon.icon" style="width: 35px; height: 35px" />
              <span>{{ icon.name.toUpperCase() }}</span>
              <!-- </router-link> -->
            </div>
          </a>
        </div>
      </div>
        <div
          class="row icon-examples dashboard"
          style="background-color: #008b88"
        >
          <div class="col-12 org" style="margin-left: -15px;">
            <a class="heading-color" href="#">Tools & Items</a>
          </div>
          <div
            class="col-lg-3 col-md-6"
            v-for="(icon, index) in lims"
            :key="icon.name + index"
          >
            <a
              type="button"
              v-b-tooltip.hover.top
              :title="icon.hovertext"
              :class="icon.classes"
              data-clipboard-text="air-baloon"
              :href="icon.path"
            >
              <!-- <div v-if="icon.name!= 'equipment' && icon.name!= 'reagents'">
                  <router-link class="navbar-brand" :to="icon.path">
                    <i :class="icon.icon"></i>
                    <span>{{ icon.name.toUpperCase()}}</span>
                  </router-link>
                </div> -->
              <div>
                <i :class="icon.icon"></i>
                <span>{{ icon.name.toUpperCase() }}</span>
              </div>
            </a>
          </div>
        </div>
        <div
          class="row icon-examples dashboard"
          style="background-color: #c3e3e2"
        >
          <div class="col-12 org" style="margin-left: -25px;">
            <a class="heading-color" href="#">Resources</a>
          </div>
          <div
            class="col-lg-3 col-md-6"
            v-for="(icon, index) in resources"
            :key="icon.name + index"
          >
            <a
              type="button"
              v-b-tooltip.hover.top
              :title="icon.hovertext"
              :class="icon.classes"
              data-clipboard-text="air-baloon"
              :href="icon.path"
            >
              <div>
                <!-- <router-link class="navbar-brand" :to="icon.path"> -->
                <img :src="icon.icon" style="width: 35px; height: 35px" />
                <span>{{ icon.name.toUpperCase() }}</span>
                <!-- </router-link> -->
              </div>
            </a>
          </div>
        </div>
        <div
          class="row icon-examples dashboard"
          style="background-color: #779aee"
        >
          <div class="col-12 org" style="margin-left: 10px;">
            <a class="heading-color" href="#">Quality Management</a>
          </div>
          <div
            class="col-lg-3 col-md-6"
            v-for="(icon, index) in quality"
            :key="icon.name + index"
          >
            <a
              type="button"
              v-b-tooltip.hover.top
              :title="icon.hovertext"
              :class="icon.classes"
              data-clipboard-text="air-baloon"
              :href="icon.path"
            >
              <div>
                <!-- <router-link class="navbar-brand" :to="icon.path"> -->
                <img :src="icon.icon" style="width: 35px; height: 35px" />
                <span>{{ icon.name.toUpperCase() }}</span>
                <!-- </router-link> -->
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";
import timeLine from "@/views/process/timeLine";
// import axios from "../utils/axiosinstance";
Vue.use(VueClipboard);
export default {
  components: { timeLine },
  directives: {
    "b-tooltip": BTooltipDirective,
  },
  data() {
    return {
      showTimeLine: false,
      showDepartment: "",
      authCookie: null,
      documents: [
        // var testBoolean = this.authCookie.departments && this.authCookie.departments[0];
        { 
        name: "Step by Step", 
        icon: "/img/icons/templates.png", 
        // path: "/documents/draft", 
        path: "/documents?docStatusType=draft&templateType=document",
        classes: "btn-icon-clipboard template",
        hovertext: "Create documents from drafting, reviewing to approval",  
        },
        // {
        //   name: "Documents",
        //   icon: "/img/icons/templates.png",
        //   path: "/artifact/doclist?docStatusType=draft&templateType=document",
        //   classes: "btn-icon-clipboard template",
        //   hovertext: "Create documents from drafting, reviewing to approval",
        // },
        {
          name: "PDFs",
          icon: "/img/icons/templates.png",
          path: "/Pdf?docStatusType=draft&isPdf=pdf",
          classes: "btn-icon-clipboard template",
          hovertext: "Creat pdfs from documents management to save and share",
        },
        {
          name: "Forms",
          icon: "/img/icons/templates.png",
          path: "/forms?docStatusType=draft&isForms=forms",
          classes: "btn-icon-clipboard template",
          hovertext: "Create froms from document management to save and share",
        },
        {
          name: "Notepad",
          icon: "/img/icons/templates.png",
          path: "/Notepad?docStatusType=draft&isNotepad=notepad",
          classes: "btn-icon-clipboard template",
          hovertext: "Create froms from document management to save and share",
        },
        {
          name: "Flowchart",
          icon: "/img/icons/templates.png",
          path: "/flowChart?docStatusType=draft&isFlowchart=flowChart",
          classes: "btn-icon-clipboard template",
          hovertext: "Add random and causal flowchart on the go",
        },
      ],
      organizer: [
        {
          name: "Daily Planner",
          icon: "ni ni-vector text-black",
          path: "/planner",
          classes: "btn-icon-clipboard template",
          hovertext: "Add random and causal planner on the go",
        },
        {
          name: "My Notes",
          icon: "ni ni-books text-orange",
          path: "/stickyNotes",
          classes: "btn-icon-clipboard template",
          hovertext: "Add random and causal work notes on the go",
        },
        // {
        //   name: "planner2",
        //   icon: "ni ni-vector text-black",
        //   path: "/planner2",
        //   classes: "btn-icon-clipboard template",
        //   hovertext: "Add random and causal planner on the go",
        // },
        {
          name: "Shared Notes",
          icon: "ni ni-books text-green",
          path: "/SharedNotes",
          classes: "btn-icon-clipboard template",
          hovertext: "Add random and causal flowchart on the go",
        },
      ],
      media: [
        {
          name: "Pdfs",
          icon: "/img/icons/video.png",
          path: "/files",
          classes: "btn-icon-clipboard template",
          hovertext: "Store all your pdf files in one place",
        },
        {
          name: "Videos",
          icon: "/img/icons/video.png",
          path: "/videos",
          classes: "btn-icon-clipboard template",
          hovertext: "Store all your media files in one place",
        },
        {
          name: "Images",
          icon: "/img/icons/video.png",
          path: "/images",
          classes: "btn-icon-clipboard template",
          hovertext: "Store all your media files in one place",
        },
      ],
      lims: [
        {
          name: "Sample Management",
          icon: "ni ni-books text-orange",
          path: "/sampleManagement?industryItemType=sampleManagement",
          classes: "btn-icon-clipboard template",
          hovertext: "Sample management in LIMs",
        },
        {
          name: "equipment",
          icon: "ni ni-vector text-black",
          path: "/equipmentList?industryItemType=equipment",
          classes: "btn-icon-clipboard template",
          hovertext: "List of added equipment details in your facility",
        },
        {
          name: "reagent",
          icon: "ni ni-support-16 text-orange",
          path: "/reagentList?industryItemType=reagent",
          classes: "btn-icon-clipboard template",
          hovertext: "List of added reagent details in your facility",
        },
      ],
      resources: [
        {
          name: "Library",
          icon: "/img/icons/library.png",
          // path: "/artifact/doclist?docStatusType=draft&templateType=library",
          path: "/Library?docStatusType=draft&type=library",
          classes: "btn-icon-clipboard template",
          hovertext:
            "Get access to sponsored documents and create your own ones",
        },
        {
          name: "Templates",
          icon: "/img/icons/templates.png",
          path: "/Template?docStatusType=draft&type=template",
          classes: "btn-icon-clipboard template",
          hovertext: "Store all your media files in one place",
        },
        {
          name: "Data Center",
          icon: "/img/icons/guests.png",
          path: "/datacenter",
          classes: "btn-icon-clipboard template",
          hovertext:
            "share, invite and add collaborators outside your organization",
        },
      ],
      quality: [
        {
          name: "Event",
          icon: "/img/icons/guests.png",
          path: "/event",
          classes: "btn-icon-clipboard template",
          hovertext: "share, invite and add collaborators outside your organization",
        },
        {
          name: "Deviation",
          icon: "/img/icons/approvers.png",
          path: "/deviation",
          classes: "btn-icon-clipboard template",
          hovertext: "Supervisor level access to control and approve documents",
        },
        {
          name: "Capa",
          icon: "/img/icons/templates.png",
          path: "/capa",
          classes: "btn-icon-clipboard template",
          hovertext: "share, invite and add users in your organization",
        },
      ],
    };
  },
  mounted() {
    this.showTimeLine = this.$route.query.showTimeLine;
    
    if(this.authCookie.departments && this.authCookie.departments[0]){
      console.log(this.authCookie.departments.length);
      const dashboards = document.querySelectorAll('.dashboard');
      for(var i=0; i< dashboards.length; i++){
        const colors = ["#ff000066", "#ff7f007a", "#ffff004f", "#00ff0059", "#0000ff54", "#4b008263", "#9400d366"];
        const color = colors[Math.floor(Math.random() * colors.length)];
        dashboards[i].style.setProperty('--beforeBack', color);
        const newDiv = document.createElement('div');
        newDiv.innerHTML = this.authCookie.departments[0].departmentName;
        newDiv.className = 'addDepartmentname';
        dashboards[i].append(newDiv);
        console.log(this.authCookie.departments[0].departmentName);
      }
      for(var j=0; j<this.documents.length; j++){
        console.log(j);
        this.documents[0].name = "Department Procedures";
        this.documents[1].name = "Referral Guidelines";
        this.documents[2].name = "Tracking Logs";
        this.documents[3].name = "Final Reports";
        this.documents[4].name = "Departmental Workflows";
      }
      for(var k=0; k<this.organizer.length; k++){
        console.log(k);
        this.organizer[0].name = "My Planner";
        this.organizer[1].name = "My Work Notes";
        this.organizer[2].name = "Departmental Shared Notes";
      }
      for(var l=0; l<this.media.length; l++){
        console.log(l);
        this.media[0].name = "Pdf Records";
      }
      // for(var m=0; m<this.quality.length; m++){
      //   console.log(m);
      //   this.quality[0].name = "Validation Report";
      //   this.quality[1].name = "Deviation/Adverse Events";
      // }
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token").substring(2).split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  methods: {
    onCopy() {
      this.$notify({
        type: "success",
        title: "Copied to clipboard",
      });
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
  },
};
</script>
<style>
.addDepartmentname{
  font-style: italic;
  position: absolute;
  bottom: 8px;
  right: 16px;
  font-size: 14px;
  text-transform: capitalize;
}
.heading-color{
  color: white;
}
.gxp-goggle {
  height: 550px;
  min-width: 900px;
  right: 16px;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: top;
  background-size: 100% auto;
  background-image: url("/img/pptx/homepage/gxp_goggle.png");
}

.dashboard {
  position: relative;
  margin-bottom: 50px;
  padding: 40px;
  color: #000;
  background: #ccc;
  text-align: center;
}

.dashboard:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 38px 0px 0px 240px;
  border-style: solid;
  border-color: var(--beforeBack,#00aff0 transparent transparent #00aff0);
  border-radius: 10px;
  /* border-color: #fff transparent transparent #fff; */
}
.btn-icon-clipboard.template {
  background-color: #f6f9fc7d;
}

.btn-icon-clipboard.template:hover {
  background-color: #fff;
  -webkit-box-shadow: rgb(0 0 0 / 10%) 0 0 0 1px, rgb(0 0 0 / 10%) 0 4px 16px;
  box-shadow: rgb(0 0 0 / 10%) 0 0 0 1px, rgb(0 0 0 / 10%) 0 4px 16px;
}
.icon-examples.dashboard {
  box-shadow: rgb(0 0 0 / 10%) 0 0 0 1px, rgb(0 0 0 / 10%) 0 4px 16px;
}
.org {
  margin-top: -35px;
  color: #233dd2;
  text-align: right;
}
.btn-icon-clipboard i {
  font-size: 1.3rem;
}
/* @media (min-width: 280px) and (max-width: 490px) {
  .org {
    margin-top: -20rem;
  }
  .btn-icon-clipboard i {
    margin-top: 12px;
  }
  i.icon.icon {
    width: 35px;
    height: 35px;
  }
  .dashboard:before {
    border-width: 26px 0px 0px 270px;
  }
} */
/* @media (min-width: 491px) and (max-width: 767px) {
  .org {
    margin-top: -20rem;
  }
  .btn-icon-clipboard i {
    margin-top: 12px;
  }
  i.icon.icon {
    width: 35px;
    height: 35px;
  }
  .dashboard:before {
    border-width: 26px 0px 0px 380px;
  }
} */
/* @media (min-width: 768px) and (max-width: 992px) {
  .org {
    margin-top: -110px;
  }
  .dashboard:before {
    border-width: 28px 0px 0px 280px;
  }
} */
/* @media (min-width: 1100px) and (max-width: 1596px) {
  .dashboard:before {
    border-width: 45px 0px 0px 300px;
  }
}
@media (min-width: 1600px) {
  .dashboard:before {
    border-width: 45px 0px 0px 420px;
  }
} */
/* .btn-icon-clipboard.template.disabled{
  pointer-events: none;
  cursor: default;
} */
.btn-icon-clipboard.template.disabled {
  cursor: not-allowed;
}



.panel-heading.collapsed .fa-chevron-down,
.panel-heading .fa-chevron-right {
  display: none;
}

.panel-heading.collapsed .fa-chevron-right,
.panel-heading .fa-chevron-down {
  display: inline-block;
}

i.fa {
  cursor: pointer;
  margin-right: 5px;
}

.collapsed ~ .panel-body {
  padding: 0;
}

</style>
